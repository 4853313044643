export default function getStrippedTextFromHTML(htmlString: string) {
  const htmlElement = document.createElement('div');

  htmlElement.innerHTML = htmlString;

  const titleElement = htmlElement.querySelector('title');
  if (titleElement) {
    titleElement.remove();
  }

  const styleElements = htmlElement.querySelectorAll('style');
  styleElements.forEach((styleElement) => {
    styleElement.remove();
  });

  const allElements = htmlElement.querySelectorAll('*');
  allElements.forEach((element) => {
    element.removeAttribute('style');
  });

  const bodyHTML = htmlElement.innerHTML;

  return bodyHTML;
}
