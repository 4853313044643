import useToast from '@blastradius/ui/hooks/use-toast';
import React from 'react';
import { useSWRConfig } from 'swr';
import api from '@customer-web-app/domains/shared/api';
import swrOptimisticUpdate from '@customer-web-app/domains/shared/services/swr-optimistic-update';
import { Incident } from '@customer-web-app/domains/incidents/models/incident';
import incidentTypesCopies from '@customer-web-app/domains/incidents/mappers/incident-types-copies';
import incidentTypeIconMapper from '@customer-web-app/domains/incident-details/mappers/incident-type-icon-mapper';

function useIncidentAssignment() {
  const [isSavingIncident, setIsSavingIncident] = React.useState(false);
  const { notification } = useToast();
  const { mutate } = useSWRConfig();

  async function assignIncident({
    incident,
    assignedTo,
    previousAssigneeName,
    assignedToName,
  }: {
    incident: Incident;
    assignedTo: string;
    previousAssigneeName: string;
    assignedToName?: string;
  }) {
    const incidentID = incident?.id;
    setIsSavingIncident(true);

    try {
      swrOptimisticUpdate<Incident>(
        `/incidents/${incidentID}`,
        (incident) => ({ ...incident, assignedTo }),
        {
          mutate,
        },
      );

      await api.patch(`/incidents/${incidentID}`, {
        assignedTo,
      });

      const incidentTypeIcon = incidentTypeIconMapper[incident?.incidentType];
      const incidentTypeCopy = incidentTypesCopies[incident?.incidentType];

      if (assignedTo === 'unassign') {
        notification({
          icon: incidentTypeIcon,
          iconContained: true,
          iconContainedColor: 'red',
          title: 'Assignee Changed',
          message: `${incidentTypeCopy} incident was unassigned from ${previousAssigneeName}`,
        });
      }

      if (!previousAssigneeName && assignedToName) {
        notification({
          icon: incidentTypeIcon,
          iconContained: true,
          iconContainedColor: 'red',
          title: 'Incident Assigned',
          message: `${incidentTypeCopy} incident was assigned to ${assignedToName}`,
        });
      }

      if (previousAssigneeName && assignedToName) {
        notification({
          icon: incidentTypeIcon,
          iconContained: true,
          iconContainedColor: 'red',
          title: 'Assignee Changed',
          message: `${incidentTypeCopy} incident assignment was changed from ${previousAssigneeName} to ${assignedToName}`,
        });
      }
    } catch (error) {
      notification({
        title: 'Incident Error',
        message: 'Incident assignment cannot be changed. Please try again.',
      });
    } finally {
      setIsSavingIncident(false);
    }
  }

  return {
    assignIncident,
    isSavingIncident,
  };
}

export default useIncidentAssignment;
