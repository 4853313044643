import React from 'react';
import dynamic from 'next/dynamic';
import { ButtonTransparent, Button } from '@blastradius/ui';
import {
  ActivityEntityTypes,
  ActivityTypes,
} from '@customer-web-app/domains/activities/models/activity';
import {
  Incident,
  IncidentStatuses,
} from '@customer-web-app/domains/incidents/models/incident';
import classNames from 'classnames';
import {
  AlertReport,
  AlertReportObservableTypes,
} from '@customer-web-app/domains/alert-reports/models/alert-report';
import { MaliciousReportFinding } from '@customer-web-app/domains/incidents/models/malicious-report-finding';
import useDialog from '@blastradius/ui/hooks/use-dialog';
import useActivity from '@customer-web-app/domains/activities/hooks/use-activity';
import getStrippedTextFromHTML from '@customer-web-app/domains/shared/services/get-stripped-text-from-html';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import useUsers from '@customer-web-app/domains/users/hooks/use-users';
import { useForm } from 'react-hook-form';
import colorPalette from '@blastradius/ui/colors';

const HTMLEditor = dynamic(
  () => import('@blastradius/ui/core-components/html-editor'),
  {
    ssr: false,
  },
);

type Props = {
  incident?: Incident;
  incidents?: Incident[];
  incidentStatus: IncidentStatuses.Closed;
  closedAsFalsePositive?: boolean;
  observables?: AlertReport[] | MaliciousReportFinding[];
  addToAllowList?: boolean;
  bulkActionCloseDialog?: () => void;
};

function IncidentStatusChangeAddCommentForm({
  incident,
  incidents,
  incidentStatus,
  closedAsFalsePositive = false,
  observables = [],
  addToAllowList = false,
  bulkActionCloseDialog,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const {
    createActivityComment,
    createBulkActivityComment,
    isCommentCreating,
  } = useActivity();

  const { activeUsers } = useUsers();
  const { user } = useAuthentication();

  const isBulkAction = incidents?.length > 0;

  // determine default comment
  let defaultComment = '';
  if (
    incidentStatus === IncidentStatuses.Closed &&
    closedAsFalsePositive &&
    observables?.length
  ) {
    defaultComment = `<p>The following IOC(s) were found to be benign${
      addToAllowList ? ' and added to an allow list ' : ' '
    }by <span data-mention-user-id="${user.id}" class='comment-user'>${
      user.name
    }</span>:</p><ul>${observables.map((observable) => {
      switch (observable.observableType) {
        // some observable types could be represented as either a AlertReport or MaliciousReportFinding,
        // which store the observable's display value at different paths
        case AlertReportObservableTypes.File:
          return `<li>${
            observable.file?.filename || observable.file?.file?.filename
          }</li>`;
        case AlertReportObservableTypes.Link:
          return `<li>${observable.url || observable.link?.url}</li>`;
        case AlertReportObservableTypes.IP:
          return `<li>${observable.ip}</li>`;
        case AlertReportObservableTypes.SenderEmail:
        case AlertReportObservableTypes.ReplyToEmail:
          return `<li>${observable.email}</li>`;
        default:
          // ignore unsupported types
          return '';
      }
    })}</ul>`;
  }

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      comment: defaultComment,
    },
  });

  const { closeDialog } = useDialog();

  function handleCloseDialog() {
    if (isBulkAction) {
      bulkActionCloseDialog();
    } else {
      closeDialog();
    }
  }

  async function handleCommentCreate() {
    const htmlComment = form.getValues('comment');

    const comment = getStrippedTextFromHTML(htmlComment);

    try {
      if (isBulkAction) {
        createBulkActivityComment({
          entityIDs: incidents?.map(({ id }) => id),
          entity: ActivityEntityTypes.Incident,
          activityType: ActivityTypes.Comment,
          description: comment,
          createdBy: user?.id,
        });
      } else {
        await createActivityComment({
          entityID: incident?.id,
          entity: ActivityEntityTypes.Incident,
          activityType: ActivityTypes.Comment,
          description: comment,
          createdBy: user?.id,
        });
      }

      handleCloseDialog();
    } catch {
      // Leaves the editor open
    }
  }

  return (
    <>
      <section
        className="h-full w-full flex flex-col items-center justify-between"
        data-testid="incident-status-change-add-comment-form"
      >
        <div className="w-full">
          <div
            className={classNames(
              'flex flex-col gap-2 !delay-0 animate-fade-in opacity-0',
              {
                'pointer-events-none': isCommentCreating,
              },
            )}
          >
            <HTMLEditor
              name="comment"
              data={`<div style="font-family:Public Sans, sans-serif;color:#303443;line-height:1.375;">${defaultComment}
                </div>`}
              form={form}
              config={{
                height: 132,
                removePlugins: ['resize', 'magicline', 'autogrow'],
                toolbar: [],
                extraPlugins: 'mentions,wordcount',
                wordcount: {
                  showParagraphs: false,
                  showWordCount: false,
                  showCharCount: false,
                  maxCharCount: 2000,
                },
                mentions: [
                  {
                    feed: activeUsers,
                    minChars: 0,
                    outputTemplate: `<div><span data-mention-user-id="{id}" class='comment-user'>{name}</span>&nbsp;</div>`,
                  },
                ],
              }}
              textarea
              className="html-editor--topless"
              customOnReadyStyles={`
                  img {max-width: 100%;} h1, h2, h3, h4, h5, h6, p, pre, address { color: ${colorPalette.gray[500]} !important; }
                  a {color: ${colorPalette.pink[500]} !important;}
                  span.comment-user { font-weight: bold !important; }
                `}
            />
          </div>
        </div>
        <div className="flex gap-4 items-center w-full justify-between">
          <ButtonTransparent
            className="px-3 h-8 !w-fit"
            onClick={() => handleCloseDialog()}
            disabled={isCommentCreating}
          >
            Skip Comment
          </ButtonTransparent>
          <Button
            color="white"
            size="x-small"
            icon="plus"
            iconSize={16}
            onClick={handleCommentCreate}
            loading={isCommentCreating}
          >
            Add Comment
          </Button>
        </div>
      </section>
    </>
  );
}

export default IncidentStatusChangeAddCommentForm;
