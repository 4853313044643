import { Icon } from '@blastradius/ui';
import { IncidentTypes } from '@customer-web-app/domains/incidents/models/incident';

const incidentTypeIconMapper: {
  [key in IncidentTypes]?: React.ComponentProps<typeof Icon>['name'];
} = {
  [IncidentTypes.BEC]: 'bec',
  [IncidentTypes.Phishing]: 'phishing',
  [IncidentTypes.Endpoint]: 'endpoint',
  [IncidentTypes.Identity]: 'suspicious-login',
  [IncidentTypes.Network]: 'network',
  [IncidentTypes.Generic]: 'alert-empty',
};

export default incidentTypeIconMapper;
