import { DropdownSearch, Text } from '@blastradius/ui';
import useUsers from '@customer-web-app/domains/users/hooks/use-users';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import normalizeString from '@customer-web-app/domains/shared/services/normalize-string';
import { Incident } from '@customer-web-app/domains/incidents/models/incident';
import orderBy from 'lodash/orderBy';
import useIncidentAssignment from '@customer-web-app/domains/incident-details/hooks/use-incident-assignment';
import classNames from 'classnames';

type Props = {
  incident: Incident;
  isLoadingIncident?: boolean;
};

function IncidentAssigneeDropdown({
  incident,
  isLoadingIncident,
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const className = classNames('flex justify-end gap-2', props.className);

  const { assignIncident, isSavingIncident } = useIncidentAssignment();

  const { activeUsers, isLoadingUsers } = useUsers();

  const [assignedTo, setAssignedTo] = React.useState<string>(
    incident?.assignedTo,
  );

  const incidentAssignedUser = React.useMemo(() => {
    return activeUsers?.find(({ id }) => id === assignedTo);
  }, [assignedTo, isLoadingUsers]);

  async function handleAssignUser(userToAssign) {
    setAssignedTo(userToAssign?.id);

    await assignIncident({
      assignedTo: userToAssign?.id,
      assignedToName: userToAssign?.name,
      incident,
      previousAssigneeName: incidentAssignedUser?.name,
    });
  }

  async function handleUnassignUser() {
    setAssignedTo('unassign');

    await assignIncident({
      assignedTo: 'unassign',
      incident,
      previousAssigneeName: incidentAssignedUser?.name,
    });
  }

  const isLoading = isLoadingIncident || isLoadingUsers;

  return (
    <div
      {...props}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {!isLoading && (
        <>
          <Text type="label" color="text-gray-500" as="div">
            Assigned to
          </Text>
          <DropdownSearch>
            <DropdownSearch.Activator
              active
              className={classNames(
                'before:!w-[calc(100%+0.75rem)] before:h-9 before:!-left-[0.375rem] h-8',
                {
                  'opacity-50': isSavingIncident,
                },
              )}
              disabled={isSavingIncident}
            >
              <Text
                type="body"
                size="small"
                as="div"
                data-testid="incident-assignee"
              >
                {incidentAssignedUser?.name || 'Unassigned'}
              </Text>
            </DropdownSearch.Activator>
            <DropdownSearch.Content className="z-40 !right-0 !top-6">
              <DropdownSearch.Header placeholder="Search users">
                {(search) => (
                  <DropdownSearch.Items emptyStateLabel="There were no users found.">
                    {orderBy(
                      activeUsers,
                      [(user) => user?.id === assignedTo, (user) => user],
                      ['desc', 'asc'],
                    )
                      .filter((user) => {
                        return normalizeString(user?.name).includes(
                          normalizeString(search),
                        );
                      })
                      .map((user) => {
                        const isUserAssigned =
                          user?.id && assignedTo === user.id;

                        return (
                          <DropdownSearch.Item
                            as="button"
                            key={user?.id}
                            selected={isUserAssigned}
                            action={{
                              label: isUserAssigned ? 'Unassign' : 'Assign',
                              onClick: () => {
                                if (isUserAssigned) handleUnassignUser();
                                else handleAssignUser(user);
                              },
                            }}
                            className="w-[calc(100%+0.50rem)]"
                          >
                            {user?.name}
                          </DropdownSearch.Item>
                        );
                      })}
                  </DropdownSearch.Items>
                )}
              </DropdownSearch.Header>
            </DropdownSearch.Content>
          </DropdownSearch>
        </>
      )}

      {isLoading && <Skeleton width={120} height={14} className="mb-4" />}
    </div>
  );
}

export default IncidentAssigneeDropdown;
