import React from 'react';
import { Dialog, Icon, Text } from '@blastradius/ui';
import {
  Incident,
  IncidentStatuses,
} from '@customer-web-app/domains/incidents/models/incident';
import { AlertReport } from '@customer-web-app/domains/alert-reports/models/alert-report';
import { MaliciousReportFinding } from '@customer-web-app/domains/incidents/models/malicious-report-finding';
import IncidentStatusChangeAddCommentForm from '@customer-web-app/domains/incidents/components/incident-status-change-add-comment-form/index';
import classNames from 'classnames';

type Props = {
  incident?: Incident;
  incidents?: Incident[];
  incidentStatus: IncidentStatuses.Closed;
  closedAsFalsePositive?: boolean;
  observables?: AlertReport[] | MaliciousReportFinding[];
  bulkActionCloseDialog?: () => void;
  addToAllowList?: boolean;
  top?: string;
};

function IncidentStatusChangeAddCommentDialogContent({
  incident,
  incidents,
  incidentStatus,
  closedAsFalsePositive = false,
  observables = [],
  bulkActionCloseDialog,
  addToAllowList = false,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const isBulkAction = incidents?.length > 0;

  return (
    <div className="h-full flex flex-col items-center justify-between pt-5 pb-6 px-6 gap-2">
      <header className="flex flex-col gap-4 items-start w-full">
        <Text type="heading" size="small" className="inline-flex items-center">
          <span className="pr-3">Status changed from</span>
          <span className="bg-gray-100 dark:bg-gray-950 gap-1 p-2 pr-3 rounded inline-flex items-center">
            <Icon
              name="incidents"
              size={18}
              className="pointer-events-none fill-pink-500"
            />
            <Text type="body" size="small">
              Open
            </Text>
          </span>
          <span className="px-3">to</span>
          {closedAsFalsePositive ? (
            <span className="bg-gray-100 dark:bg-gray-950 gap-1 p-2 pr-3 rounded inline-flex items-center">
              <Icon
                name="false-positive"
                size={20}
                className="pointer-events-none"
              />
              <Text type="body" size="small">
                False Positive
              </Text>
            </span>
          ) : (
            <span className="bg-gray-100 dark:bg-gray-950 gap-1 p-2 pr-3 rounded inline-flex items-center">
              <Icon
                name="incident-closed"
                size={20}
                className="pointer-events-none"
              />
              <Text type="body" size="small">
                Closed
              </Text>
            </span>
          )}
        </Text>
        <Text
          type="heading"
          size="x-small"
          color="text-gray-300"
          className="text-left"
        >
          Add status change comment{' '}
          {isBulkAction
            ? `for ${incidents?.length} incident${
                incidents?.length > 1 ? 's' : ''
              }`
            : ''}
        </Text>
      </header>
      <IncidentStatusChangeAddCommentForm
        incident={incident}
        incidents={incidents}
        incidentStatus={incidentStatus}
        closedAsFalsePositive={closedAsFalsePositive}
        observables={observables}
        bulkActionCloseDialog={bulkActionCloseDialog}
        addToAllowList={addToAllowList}
      />
    </div>
  );
}

function IncidentStatusChangeAddCommentDialog({
  top = '!top-[53%] 2xl:!top-[12%]',
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const className = classNames('-mt-[24rem] 2xl:mt-0', top);

  return (
    <Dialog
      color="white"
      height="h-[19.75rem]"
      data-testid="incident-status-change-add-comment-dialog"
      className={className}
    >
      <IncidentStatusChangeAddCommentDialogContent {...props} />
    </Dialog>
  );
}

export default IncidentStatusChangeAddCommentDialog;
